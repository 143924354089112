[data-cookie-consent-setting='true'] {
  cursor: pointer;
}
#mainContainer {
  * {
    box-sizing: border-box;
  }
  p {
    margin: unset !important;
    all: unset;
  }
  a {
    color: inherit;
    &:active {
      color: inherit;
    }
  }
  @media screen and (max-width: 900px) {
    max-width: 550px;
    width: 90% !important;
  }
  transition: opacity 300ms, visibility 300ms;
  padding: 0px 10px;
  z-index: 2147483646;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  .mainDiv {
    @media screen and (max-width: 900px) {
      flex-direction: column;
      padding: 15px 25px;
    }
    height: 100%;
    padding: 24px 36px;
    display: flex;
    min-height: 100px;
    gap: 10px;
    box-shadow: 0 0 14px 0px rgb(2, 2, 3, 0.28);
    .mainTextDiv {
      flex-basis: 70%;
      &Title {
        margin-bottom: 24px;
      }
      &Description {
        margin-bottom: 24px;
      }
      &Customize {
        letter-spacing: unset;
        &Btn {
          text-underline-offset: 2px;
          cursor: pointer;
        }
      }
    }
    .mainButtonsDiv {
      flex-basis: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: 900px) {
        margin-top: 20px;
      }
      button {
        @media screen and (max-width: 900px) {
          max-width: unset !important;
        }
        height: 40px;
      }
      .primaryBtn {
        margin-bottom: 20px !important;
        cursor: pointer;
        transition: all ease-out 0.3s;
        max-width: 220px;
        height: 55px;
      }
      .secondaryBtn {
        letter-spacing: unset;
        cursor: pointer;
        transition: all ease-out 0.3s;
        max-width: 220px;
        height: 55px;
      }
    }
  }
}
#modal {
  * {
    box-sizing: border-box;
  }
  p {
    margin: unset !important;
    all: unset;
  }
  a {
    color: inherit;
    &:active {
      color: inherit;
    }
  }
  table {
    all: unset;
  }
  @media screen and (max-width: 600px) {
    height: 100vh !important;
  }
  z-index: 2147483647;
  display: flex;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  transition: visibility 300ms, opacity 300ms;
  .modalContentWrapper {
    @media screen and (max-width: 600px) {
      height: 100% !important;
      width: 100% !important;
      border-radius: 0 !important;
    }
    position: relative;
    margin: auto;
    padding: 0;
    width: 95%;
    max-width: 720px;
    height: 500px;
    overflow-y: auto;
    border-radius: 6px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 19px 3px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 19px 3px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 19px 3px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    flex-direction: column;
    top: -5%;
    transition: top 300ms;
    .modalContentHeader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      border-bottom: solid 1px black;
      padding: 20px 28px;
      .modalContentHeaderTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .closeButton {
        font-size: 27px;
        font-family: Georgia, Times New Roman;
        font-weight: bolder;
        width: 35px;
        height: 35px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        transition: 0.2s;
        color: white;
        &:hover {
          transition: 0.2s;
        }
      }
    }
    .modalContent {
      padding: 20px 28px;
      overflow-y: auto;
      border-bottom: 1px solid black;
      width: 100%;
      &Title {
        letter-spacing: 0px;
      }
      &Desc {
        letter-spacing: 0px;
        margin: 20px 0px 30px 0px;
      }
      .accordionWrapper {
        width: 100%;
        margin-bottom: 10px;
        .accordion {
          transition: all ease-out 0.3s;
          background-color: #eee;
          cursor: pointer;
          padding: 18px;
          border: none;
          text-align: left;
          outline: none;
          font-size: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 6px;
          position: relative;
          .arrow {
            border: solid black;
            border-width: 0 2px 2px 0;
            padding: 0.2em;
            display: inline-block;
            position: absolute;
            content: '';
            margin-right: 15px;
            transform: translateY(-50%) rotate(45deg);
            left: 22px;
            top: 48%;
          }

          .arrowReverse {
            transform: translateY(-50%) rotate(-135deg);
            top: 53%;
          }
          .accordionTitle {
            padding-left: 25px;
          }
          .inputCheckbox {
            height: 0;
            width: 0;
            visibility: hidden;
            display: none;
            &:checked {
              + label {
                &::after {
                  left: calc(100% - 2px);
                  transform: translateX(-100%);
                }
              }
            }
            &:disabled + .label {
              &:active {
                &::after {
                  width: 20px !important;
                }
              }
            }
          }
          .label {
            cursor: pointer;
            text-indent: -9999px;
            width: 54px;
            height: 24px;
            display: block;
            border-radius: 100px;
            position: relative;
            &::after {
              content: '';
              position: absolute;
              top: 2px;
              left: 2px;
              width: 20px;
              height: 20px;
              background: #fff;
              border-radius: 20px;
              transition: 0.3s;
            }
            &:active {
              &::after {
                width: 20px;
              }
            }
          }
        }
        .accordionPanel {
          display: none;
          overflow: hidden;
          border-radius: 0px 0px 6px 6px;
          background-color: #eee;
          &Desc {
            padding: 20px 18px 30px 18px;
          }
          .tableContainer {
            .inputCheckbox {
              height: 0;
              width: 0;
              visibility: hidden;
              display: none;
              &:checked {
                + label {
                  &::after {
                    left: calc(100% - 2px);
                    transform: translateX(-100%);
                  }
                }
              }
            }
            .label {
              cursor: pointer;
              text-indent: -9999px;
              width: 54px;
              height: 24px;
              display: block;
              border-radius: 100px;
              position: relative;
              &::after {
                content: '';
                position: absolute;
                top: 2px;
                left: 2px;
                width: 20px;
                height: 20px;
                background: #fff;
                border-radius: 20px;
                transition: 0.3s;
              }
              &:active {
                &::after {
                  width: 20px;
                }
              }
            }
            .table {
              display: table;
              table-layout: fixed;
              border-collapse: collapse;
              word-break: break-word;
              width: 100%;
              .tbody {
                border-color: inherit;
                .tr {
                  border-color: inherit;
                  .th {
                    border-color: inherit;
                  }
                  .td {
                    border-color: inherit;
                  }
                }
              }
            }
            padding: 20px;
            border-top: solid 2px white;
            th {
              width: 46%;
              text-align: left;
            }
            td {
              width: 46%;
            }
            th,
            td {
              border: none;
              vertical-align: top;
              padding: 7px 0px;
              font-weight: 300;
            }
          }
        }
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 100%;
        background: transparent;
        border-radius: 0 0.25em 0.25em 0;
      }
      &::-webkit-scrollbar-thumb {
        background: #eeeeee;
        border-radius: 100em;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #cccccc;
        border-radius: 100em;
      }
    }
    .modalButtonsDiv {
      padding: 28px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
      & button {
        transition: all ease-out 0.3s;
        min-width: 220px;
        max-width: 300px;
        cursor: pointer;
        padding: 15px 25px;
        @media screen and (max-width: 600px) {
          max-width: unset;
          &:first-child {
            margin-bottom: 20px !important;
          }
        }
      }
    }
  }
}
.active {
  border-radius: 6px 6px 0px 0px !important;
}
